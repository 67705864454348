<template>
  <div>
    <page-content>
      <h1 style="margin-bottom: 30px">
        修改手机号
      </h1>
      <!-- 旧手机号验证 -->
      <el-form
        v-show="stepShow"
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="130px"
        class="demo-ruleForm"
      >
        <el-form-item
          label="旧手机号："
          prop="mobile"
        >
          {{ ruleForm.mobile?ruleForm.mobile:'--' }}
        </el-form-item>
        <el-form-item
          label="验证码："
          prop="code"
        >
          <el-input
            v-model="ruleForm.code"
            class="width200"
            size="mini"
            maxlength="5"
            placeholder="请输入验证码"
          ></el-input>
          <el-button
            v-if="!showCode && stepShow"
            :key="1"
            size="mini"
            type="success"
            style="margin-left: 10px"
            @click="getMobileCode"
          >
            获取验证码
          </el-button>
          <el-button
            v-if="showCode && stepShow"
            :key="1"
            size="mini"
            style="margin-left: 10px"
          >
            {{ countdown }}s
          </el-button>
        </el-form-item>
        <!-- <el-form-item
          label="密码："
          prop="password"
        >
          <el-input
            v-model="ruleForm.password"
            class="width200"
            size="mini"
            maxlength="10"
            placeholder="请输入密码"
          ></el-input>
        </el-form-item> -->
        <el-form-item>
          <el-button
            type="primary"
            size="small"
            @click="submitFormOld('ruleForm')"
          >
            下一步
          </el-button>
        </el-form-item>
      </el-form>
      <!-- 新手机号验证 -->
      <el-form
        v-show="!stepShow"
        ref="ruleFormNew"
        :model="ruleFormNew"
        :rules="rulesNew"
        label-width="130px"
        class="demo-ruleForm"
      >
        <el-form-item
          label="新手机号："
          prop="mobile"
        >
          <el-input
            v-model="ruleFormNew.mobile"
            class="width200"
            size="mini"
            maxlength="11"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="验证码："
          prop="code"
        >
          <el-input
            v-model="ruleFormNew.code"
            class="width200"
            size="mini"
            maxlength="5"
            placeholder="请输入验证码"
          ></el-input>
          <el-button
            v-if="!showCodeNew"
            :key="2"
            size="mini"
            type="success"
            style="margin-left: 10px"
            @click="getNewMobileCode"
          >
            获取验证码
          </el-button>
          <el-button
            v-if="showCodeNew"
            :key="2"
            size="mini"
            style="margin-left: 10px"
          >
            {{ newCountdown }}s
          </el-button>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            size="small"
            @click="submitFormNew('ruleFormNew')"
          >
            确认
          </el-button>
          <el-button
            size="small"
            @click="stepShow = true"
          >
            取消
          </el-button>
        </el-form-item>
      </el-form>
    </page-content>
  </div>
</template>

<script>
import { sendCode } from '@/service/commonService'
import { changeMobileVerify, changeMobile } from '@/service/userService'

export default {
  name: 'ExtractDetail',
  data() {
    return {
      query: this.$route.query,
      ruleForm: {
        mobile: '',
        code: ''
      },
      rules: {
        code: [
          { required: true, message: '请输入验证码', trigger: 'change' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'change' }
        ]
      },
      roleArr: [],
      ruleFormNew: {
        mobile: '',
        code: ''
      },
      rulesNew: {
        userRealName: [
          { required: true, message: '请输入新手机号', trigger: 'change' }
        ]
      },
      stepShow: true, // 显示新手机号编辑模块
      countdown: 60, // 倒计时
      newCountdown: 60,
      showCode: false, // 显示倒计时
      showCodeNew: false, // 新倒计时
      timeStatus: '', // 定时器
      timeStatusNew: ''
    }
  },
  mounted() {
    this.ruleForm.mobile = this.$route.query.mobile
  },
  destroyed() {
    clearTimeout(this.timeStatus)
    clearTimeout(this.timeStatusNew)
  },
  methods: {
    // 检验旧手机号
    submitFormOld(formName) {
      clearTimeout(this.timeStatus)
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          let { code } = await changeMobileVerify(this.ruleForm)
          if (code === 0) {
            this.stepShow = false
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 修改新手机号
    submitFormNew(formName) {
      clearTimeout(this.timeStatusNew)
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.stepShow = false
          this.$confirm('确定修改账号信息吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async() => {
            let { code } = await changeMobile(this.ruleFormNew)
            if (code === 0) {
              this.$message.success('修改成功')
              this.$router.replace('/login')
            }
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消'
            })
          })
        } else {
          return false
        }
      })
    },
    // 获取验证码
    async getMobileCode() {
      this.showCode = true
      let { data, code } = await sendCode({ mobile: this.ruleForm.mobile, sendScene: 'CHANGE_MOBILE' })
      if (code === 0) {
        this.settime('old')
        this.$message.success('发送成功')
      }
    },

    // 获取验证码
    async getNewMobileCode() {
      this.showCodeNew = true
      let { data, code } = await sendCode({ mobile: this.ruleFormNew.mobile, sendScene: 'CHANGE_MOBILE' })
      if (code === 0) {
        this.settime('new')
        this.$message.success('发送成功')
      }
    },
    // 倒计时
    settime(type) {
      let that = this
      if (this.countdown == 0) {
        if (type === 'old') {
          this.showCode = false
          this.countdown = 60
        }
        if (type === 'new') {
          this.showCodeNew = false
          this.newCountdown = 60
        }
        return
      } else {
        if (type === 'old') {
          this.countdown--
        }
        if (type === 'new') {
          this.newCountdown--
        }
      }
      if (type === 'old') {
        this.timeStatus = setTimeout(function() {
          that.settime('old')
        }, 1000)
      }
      if (type === 'new') {
        this.timeStatusNew = setTimeout(function() {
          that.settime('new')
        }, 1000)
      }
    }
  }
}
</script>
<style scoped>
.width360 {
  width: 360px;
}
.width200{
  width: 200px;
}
</style>
